<template>
  <div>
    <el-dialog class="mydialog" title="提示" :visible.sync="dowshow" :width="isMobileDevice?'80%':'440px'" :modal="false">
        <div style="width: 100%;padding: 20px;">
            <img src="@/assets/applogo.png" style="width: 80%;" />
           
        </div>
        <div style="width: 100%;padding-bottom: 20px;">
                <div class="pcbtn" @click="dowanzhuo()">安卓下载</div>
                <!-- <div class="pcbtn">苹果下载</div> -->
            </div>
            <div style="width: 100%;margin-top: 10px;padding-bottom: 20px;text-align: center;">
                <i class="el-icon-circle-close" style="font-size: 48px;color: #ccc;" @click="closeshow()"></i>
            </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dowshow: false,
      isMobileDevice: false,
    };
  },
  methods: {
    dowanzhuo(){
      // this.$message.success('开始下载')

      //  window.location.href = 'http://download.meirenyin.net/appdown/shengbao.apk'
      const userAgent = navigator.userAgent;

      if (userAgent.includes("Mi") || userAgent.includes("Xiaomi")) {
        window.location.href = "https://app.mi.com/details?id=com.xingqu.one&ref=search"; // 小米应用商店链接
      } else {
        // 其他逻辑
        window.location.href = "https://sj.qq.com/appdetail/com.xingqu.one";
      }
      
    },
    openshow() {
      this.checkDeviceType()
      this.dowshow = true;
    },
    closeshow(){
        this.dowshow = false
    },
    checkDeviceType() {
      if( window.innerWidth <= 640){
       this.isMobileDevice =true
      }else{
        this.isMobileDevice =false
      }
      console.log(' this.isMobileDevice', this.isMobileDevice);
    },
  },
};
</script>

<style scoped>
.pcbtn {
  width: 70%;
  height: 50px;
  background-color: #ffe03f;
  margin: 10px auto;
  border-radius: 6px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__footer {
  display: none;
}
::v-deep .mydialog .el-dialog .el-dialog__body {
  padding: 0px;
  border-radius: 12px;
  background-color: white !important;
}
::v-deep .mydialog .el-dialog {
  border-radius: 12px;
  background-color: white !important;
  box-shadow: 2px 4px 6px #ccc;
}
</style>
