<template>
  <div>
    <div class="main" v-if="!isMobileDevice">
      <div class="pcbtnbox">
        <div class="pcbtnitem">
          <div class="pcbtn" @click="dowanzhuo()">安卓下载</div>
          <!-- <div class="pcbtn">苹果下载</div> -->
        </div>
      </div>
      <div style="margin-left: 80px; display: flex">
        <img
          src="@/assets/logo.png"
          style="width: 200px; height: 100px; object-fit: contain"
          alt=""
        />
        <div
          style="
            margin-left: 50px;
            color: #1b1b1b;
            font-size: 18px;
            height: 100px;
            line-height: 100px;
          "
          class="hoverjr"
          @click="goh5()"
        >
          网页版
        </div>
        <!-- <div
          style="
            margin-left: 50px;
            color: #1b1b1b;
            font-size: 18px;
            height: 100px;
            line-height: 100px;
            cursor: pointer;
          "
          @click="godaili()"
          class="hoverjr"
        >
          加入声宝
        </div> -->
      </div>
      <div class="yellwobg"></div>
      <div class="phonelist"></div>
    </div>
    <div v-else style="width: 100%; height: 100vh; overflow: hidden">
      <div style="display: flex">
        <img
          src="@/assets/logo.png"
          style="width: 150px; height: 100px; object-fit: contain"
          alt=""
        />
        <div
          style="
            width: 25%;
            margin-left: 20px;
            color: #1b1b1b;
            font-size: 18px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          "
          class="hoverjr"
          @click="goh5()"
        >
          网页版
        </div>
        <!-- <div
          style="
            width: 25%;
            margin-left: 10px;
            color: #1b1b1b;
            font-size: 18px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          "
          @click="godaili()"
          class="hoverjr"
        >
          加入声宝
        </div> -->
      </div>
      <div style="width: 100%; margin-top: 0px">
        <img
          src="@/assets/texttitle.png"
          style="
            width: 200px;
            height: 120px;
            margin: 0 auto;
            display: block;
            object-fit: contain;
          "
          alt=""
        />
      </div>
      <div style="width: 100%; margin-top: 10px">
        <img
          src="@/assets/smphone.png"
          style="
            width: 80%;
            height: 65vh;
            margin: 0 auto;
            display: block;
            object-fit: contain;
          "
          alt=""
        />
      </div>
      <div class="bottombox">
        <div class="bottomitem">
          <div class="bottombtn" @click="dowanzhuo()">安卓下载</div>
          <!-- <div class="bottombtn">苹果下载</div> -->
        </div>
      </div>
      <div class="h5lxwm" @click="showh5()">
        <i
          class="el-icon-chat-dot-round"
          style="font-weight: bold; font-size: 38px; margin-top: 10px"
        ></i>
      </div>
    </div>
    <div class="footer" v-if="!isMobileDevice">
      <p>© 2024 苏州美人音网络科技有限公司版权所有</p>
      <p>
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >苏ICP备2024101803号-2
        </a>
        <!-- <span>|</span>
        <a href="https://www.beian.gov.cn/" target="_blank"
          >公安备案号 XXXXXXXX号</a
        > -->
      </p>
    </div>
    <div class="lxwmbox" v-if="!isMobileDevice">
      <div class="lxwmtext">联系我们</div>
      <div style="margin-top: 20px; font-weight: bold; display: flex">
        <i
          class="el-icon-message"
          style="font-weight: bold; font-size: 24px; display: block"
        ></i>
        <span
          style="
            margin-left: 5px;
            font-size: 18px;
            display: block;
            padding-top: 1px;
          "
          >{{ email }}</span
        >
      </div>
      <div style="margin-top: 20px; display: flex">
        <i
          class="el-icon-chat-round"
          style="font-weight: bold; font-size: 24px; display: block"
        ></i>
        <span
          style="
            margin-left: 5px;
            font-size: 18px;
            display: block;
            padding-top: 1px;
            font-weight: bold;
          "
          >{{ weixin }}</span
        >
        <!-- <span
          style="
            margin-left: 5px;
            font-size: 14px;
            display: block;
            padding-top: 0px;
            color: #ffe03f;
            font-weight: 500;
            cursor: pointer;
          "
          @click="copy()"
          >点击复制</span
        > -->
      </div>
      <div style="margin-top: 10px; height: 70px; width: 100%; display: flex">
        <img
          src="@/assets/wx.jpg"
          style="width: 70px; height: 70px; display: block; object-fit: contain"
          alt=""
        />
        <span
          style="
            font-size: 14px;
            color: black;
            display: block;
            padding-left: 5px;
            height: 70px;
            line-height: 70px;
          "
          >扫描二维码加我</span
        >
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="h5lxshow" width="80%">
      <div
        style="
          width: 80%;
          border-radius: 12px;
          background-color: #fff;
          margin: 20px auto;
          padding: 30px;
        "
      >
        <div class="lxwmtext">联系我们</div>
        <div style="margin-top: 35px; font-weight: bold; display: flex">
          <i
            class="el-icon-message"
            style="font-weight: bold; font-size: 24px; display: block"
          ></i>
          <span
            style="
              margin-left: 5px;
              font-size: 18px;
              display: block;
              padding-top: 1px;
            "
            >{{ email }}</span
          >
        </div>
        <div style="margin-top: 20px; display: flex">
          <i
            class="el-icon-chat-round"
            style="font-weight: bold; font-size: 24px; display: block"
          ></i>
          <span
            style="
              margin-left: 5px;
              font-size: 18px;
              display: block;
              padding-top: 1px;
              font-weight: bold;
            "
            >{{ weixin }}</span
          >
          <!-- <span
            style="
              margin-left: 5px;
              font-size: 14px;
              display: block;
              padding-top: 0px;
              color: #ffe03f;
              font-weight: 500;
              cursor: pointer;
            "
            @click="copy()"
            >点击复制</span
          > -->
        </div>
        <div
          style="
            margin-top: 10px;
            height: 100px;
            margin: 20px auto;
            width: 100px;
          "
        >
          <img
            src="@/assets/wx.jpg"
            style="width: 100px; height: 100px"
            alt=""
          />
        </div>
        <div
          style="
            width: 100%;
            text-align: center;
            padding-top: 5px;
            font-size: 14px;
            color: #ccc;
          "
        >
          长按二维码保存到相册到微信扫一扫
        </div>

        <div style="width: 100%; text-align: center; margin-top: 40px">
          <i
            class="el-icon-circle-close"
            @click="closeh5()"
            style="font-size: 38px; color: #ccc"
          ></i>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import VConsole from 'vconsole';
// const vConsole = new VConsole();

export default {
  data() {
    return {
      isMobileDevice: false,
      weixin: "newgirl5252",
      email: "jsmry188@163.com",
      h5lxshow: false,
    };
  },
  methods: {
    dowanzhuo() {
      // this.$message.success("开始下载");

      // window.location.href =
      //   "http://download.meirenyin.net/appdown/shengbao.apk";
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Mi") || userAgent.includes("Xiaomi")) {
        window.location.href = "https://app.mi.com/details?id=com.xingqu.one&ref=search"; // 小米应用商店链接
      }  else {
        // 其他逻辑
        window.location.href = "https://sj.qq.com/appdetail/com.xingqu.one";
      }
    },
    checkDeviceType() {
      if (window.innerWidth <= 640) {
        this.isMobileDevice = true;
      } else {
        this.isMobileDevice = false;
      }
      console.log(" this.isMobileDevice", this.isMobileDevice);
    },
    godaili() {
      this.$router.push("/daili");
    },
    copy() {
      navigator.clipboard.writeText(this.weixin).then((res) => {
        this.$message.success("复制成功");
      });
    },
    showh5() {
      this.h5lxshow = true;
    },
    closeh5() {
      this.h5lxshow = false;
    },
    goh5() {
      this.$router.push("/h5");
    },
  },
  mounted() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
};
</script>

<style scoped>
.main {
  background: url("@/assets/bg2.png") no-repeat;
  background-size: contain;

  height: 100vh;
  min-height: 750px;
  background-color: #fff;
  position: relative;
  background-position: bottom 20px left 0;
  background-repeat: no-repeat;
  overflow: hidden;
}
.yellwobg {
  position: absolute;
  width: 300px;
  height: 150px;
  left: 39%;
  top: 10%;
  background: url("@/assets/texttitle.png") no-repeat;
  background-size: contain;
}
.phonelist {
  position: absolute;
  height: 500px;
  width: 60%;
  left: 25%;
  bottom: 10%;
  background: url("@/assets/phone.png") no-repeat;
  background-size: contain;
}
.hoverjr {
  user-select: none;
  /* border-bottom: 2px solid #ccc; */
}
.bottombox {
  width: 100%;
  height: 30vh;
  position: fixed;
  bottom: 0px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, hsla(0, 0%, 100%, 0)),
    color-stop(60%, #fff)
  );
  background: -webkit-linear-gradient(top, hsla(0, 0%, 100%, 0) 25%, #fff 60%);
  background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 25%, #fff 60%);
}
.bottomitem {
  width: 80%;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  padding-top: 20vh;
}
.bottombtn {
  width: 45%;
  height: 50px;
  background-color: #ffe03f;
  margin: 0 auto;
  border-radius: 6px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}
.pcbtnbox {
  position: fixed;
  right: 50px;
  top: 20px;
  width: 280px;
}
.pcbtnitem {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: nowrap;
}
.pcbtn {
  width: 120px;
  height: 50px;
  background-color: #ffe03f;
  margin: 0 auto;
  border-radius: 6px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  cursor: pointer;
}
.footer {
  background-color: #f5f5f5;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  color: #666;
  border-top: 1px solid #e5e5e5;
}

.footer a {
  color: #666;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
.footer span {
  margin: 0 10px;
}
.lxwmbox {
  width: 220px;
  border: 3px solid black;
  border-radius: 12px;
  position: fixed;
  right: 60px;
  top: 100px;
  padding: 15px;
}
.lxwmtext {
  font-weight: bold;
  font-size: 24px;
}
.h5lxwm {
  width: 54px;
  height: 54px;
  border-radius: 50%;
  border: 2px solid #ffe03f;
  position: fixed;
  bottom: 150px;
  right: 6px;
  color: white;
  line-height: 54px;
  text-align: center;
  background-color: #ffe03f; /* 盒子的背景颜色 */
  box-shadow: 2px 4px 8px rgba(255, 224, 63, 0.8);
}
::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__footer {
  display: none;
}
::v-deep .el-dialog__body {
  padding: 0px;
  border-radius: 12px;
  background-color: transparent !important;
}
::v-deep .el-dialog {
  border-radius: 12px;
  background-color: transparent !important;
}
</style>
